import React from 'react'
import github from '../images/github.svg'
import linkedin from '../images/linkedin.svg'
import twitter from '../images/twitter.svg'
import logo from '../images/mainLogo.svg'
import hackerrank from '../images/technologies/hackerrank.svg'

const Footer = () => {
  return (
    <footer>
      <div className='footerbar'>
        <a href="/" title="oubaida logo" className='logo'><img src={logo} alt="" /></a>
        <div className='iconSocail'>
              <a href="https://github.com/youssefouba" target="_blank"><img src={github} alt="github" /></a>
              <a href="https://www.linkedin.com/in/youssef-oubaida/" target="_blank"><img src={linkedin} alt="linkedin" /></a>
              <a href="https://twitter.com/itsyussf" target="_blank"><img src={twitter} alt="twitter" /></a>
              <a href="https://www.hackerrank.com/youssefoubaida11?hr_r=1" target="_blank"><img src={hackerrank} alt="hackerrank" /></a>
        </div>
    </div>
    <h4>© Designed & Built by Youssef Oubaida</h4>
    </footer>
  )
}

export default Footer