import React from "react";
import Project from "./Project";
import { useState } from "react";
import dataProjects from "../DataProjects";


const Projects = () => {
  const [projects, setProjects] = useState(dataProjects);
  return (
    <div className="Projects" id="projects">
      <div className="ProContainer">
        <h3 className="sectionTitle">Projects</h3>
        {projects.map((e, i) => (
          <Project Title={e.Title} Description={e.Description} Thumbnail={e.Thumbnail} techList={e.techList} demoLink={e.demoLink} githubLink={e.githubLink} key={i}/>
        ))}
      </div>
    </div>
  );
};

export default Projects;
