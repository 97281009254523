import React from "react";

const Header = ({menuClick}) => {
  return (
    <div className={menuClick?"container header space":"container header"}>
      <div className="content">
        <p>Hi, my name is</p>
        <h1>Youssef Oubaida</h1>
        <h2>Built Successful Web Solutions</h2>
        <p>
          Transforming complex ideas into elegant and scalable front-end
          solutions.
        </p>
        <button onClick={()=>window.location.href='mailto:mr.oubaidayoussef@gmail.com'}>Contact Me</button>
      </div>
    </div>
  );
};

export default Header;
