import React, { useState } from 'react'
import google from "../images/technologies/google.svg"
import hacker from "../images/technologies/hackerrank.svg"
import dataSkills from '../DataSkills'



const Skills = () => {
    const [activeBox,setBox]=useState(0);
    const [allSkills,setSkills]=useState(dataSkills);


  return (
    <div className='skills' id="skills">
        <h3 className="sectionTitle">Skills and Certifications</h3>
        <div className='slider'>
            <div className='sliderControl'>
                {allSkills.map((e,i)=><button key={i} onClick={()=>{setBox(i)}} className={activeBox===i ? "active":""}>{e.title}</button>)}
            </div>

            {allSkills.map((e,i)=>
                activeBox==i?
                <div className='content' key={i}>
                        <h4>Technologies :</h4>
                        <div className='items'>   
                            {e.names.map((t,ind)=><span key={ind}><img src={"images/technologies/"+e.images[ind]} alt={t+" logo"} />{t}</span>)}
                        </div>
                        {e.certi.length?<h4>Certificates :</h4>:""}
                    <div className='items2'>
                        {e.certi.map((c,i)=>
                        <a href={c[2]} target="_blank"><img src={c[1]} alt="react logo"/>{c[0]}</a>

                        )}
                    </div>
                </div>:""
            )}
        </div>
    </div>
  )
}

export default Skills