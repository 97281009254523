import React from 'react'
import About from './About'
import Projects from './Projects'
import Contact from './Contact'
import Footer from './Footer'
import Skills from './Skills'


const Main = () => {
  return (
    <main>
      <About/>
      <Projects/>
      <Skills/>
      <Contact/>
      
    </main>
  )
}

export default Main