import Loading from "./Components/Loading";
import Navbar from "./Components/Navbar";
import Main from "./Components/Main";
import Footer from "./Components/Footer";
import { useEffect, useState } from "react";
import Socials from "./Components/Socials";
import Header from "./Components/Header";

function App() {
  const [load, setload] = useState(true);
  const [menuClick, setMenu] = useState(false);
  const [pageY, setPageY] = useState(0);
  const [social, setSocial] = useState(false);
  
  const scrolling = () => {
    const py = window.scrollY;
    const navbar = document.querySelector(".backContainer");
    if (typeof window !== "undefined") {
      if (py > 100) {
        setSocial(true);
      }
      if (pageY < py) {
        navbar.classList.remove("scrollTrue");
        navbar.classList.add("scrollFalse");
      } else {
        navbar.classList.remove("scrollFalse");
        navbar.classList.add("scrollTrue");
      }
      if (py == 0) navbar.classList.remove("scrollTrue");
      setPageY(py);
    }
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", scrolling);
    }
    return () => {
      window.removeEventListener("scroll", scrolling);
    };
  }, [pageY]);

  setTimeout(() => {
    setload(false);
  }, 3000);
  
  if(load)return <Loading />

  return (
    <div className={!menuClick ? "App" : "App scrolN" }>
      
          <div className="firstback">
            <Navbar setMenu={setMenu} menuClick={menuClick} />
            <Header menuClick={menuClick} />
          </div>
          <Main />
          {social && <Socials />}
          <Footer />
    </div>
  );
}

export default App;
