const projects=[
    {
      Title: "Redbubble Browser extension",
      Description:"The Browser Extension is a web application developed specifically for Redbubble shop owners to optimize their time on the platform and achieve significant growth in their stores. By automating the liking and favoriting process, the extension helps gain likes and followers in return while also being recommended to new users.",
      Thumbnail: "/images/chrome.jpg",
      techList: ["HTML", "CSS", "Javascipt", "Gumroad"],
      demoLink: "https://oubitools.gumroad.com/l/FavoriteBubble",
      githubLink: "",
    },
    {
      Title: "Multi-step form",
      Description:
        "The multi-step form offers users a step-by-step input process, ensuring accurate data entry through real-time validation and feedback. It is a dynamic, customizable, and responsive form.",
      Thumbnail: "/images/MutliSteep.jpg",
      techList: ["React", "Sass", "Redux", "Figma"],
      demoLink: "https://funny-frangollo-d67cf6.netlify.app/",
      githubLink: "https://github.com/youssefouba/Multi_step_form",
    },
    {
      Title: "URL shortening web app",
      Description:
        "The project included a form for users to input a URL, which had validation and error handling. Once the form was submitted, the user was redirected to the shortened URL.",
      Thumbnail: "/images/urlLanding.jpg",
      techList: ["React", "Sass", "API", "Figma"],
      demoLink:
        "https://youssefouba.github.io/URL-shortening-API-landing-page/",
      githubLink:
        "https://github.com/youssefouba/URL-shortening-API-landing-page",
    },
    {
      Title: "Bookmark landing page",
      Description:
        "This is a clean and simple interface landing page built with react and sass. It uses Firebase for the contact form. The purpose of this landing page is to provide a sleek and modern interface for users to learn more about a product",
      Thumbnail: "/images/bookmark.jpg",
      techList: ["React", "Sass", "Firebase", "Figma"],
      demoLink: "https://youssefouba.github.io/Bookmark-landing-page/",
      githubLink: "https://github.com/youssefouba/Bookmark-landing-page",
    },
    {
      Title: "Tip calculator app",
      Description:
        "This tip calculator app will allow you to input your bill amount and select a tip percentage. It will then calculate the tip amount and the total bill amount. You can also select the number of people that the bill should be split between.",
      Thumbnail: "/images/tipCulclator.jpg",
      techList: ["React", "CSS", "Figma"],
      demoLink: "https://ephemeral-nasturtium-daa8db.netlify.app/",
      githubLink: "https://github.com/youssefouba/Tip-calculator-app",
    },
  ]

  export default projects;