import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDR4qDicH3tVvh6SW3jBy1bqWuYPGl3yVY",
  authDomain: "protfolio-contact.firebaseapp.com",
  projectId: "protfolio-contact",
  storageBucket: "protfolio-contact.appspot.com",
  messagingSenderId: "664568262184",
  appId: "1:664568262184:web:916110b830ff05c5f68f1d",
  measurementId: "G-5RE565FJJP"
};

const app = initializeApp(firebaseConfig);
export const db=getFirestore(app);
const analytics = getAnalytics(app);