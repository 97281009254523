import React from 'react'
import success from '../images/success.svg'

const Notifcation = () => {
  return (
    <div className='notif'>
        <p><img src={success} alt="send icon" /> Send Successfully</p>
        <div className='barnoti'></div>
    </div>
  )
}

export default Notifcation