import React from 'react'
import logo from '../images/mainLogo.svg'

const Loading = () => {
  return (
    <div className="loading">
      <div className="center mask">
        <img src={logo} alt="logo"/>
      </div>
    </div>
  )
}

export default Loading