import React from 'react'
import Mail from '../images/mail1.svg'


const Socials = () => {
  return (
    <div className='Social'>
        <div className='contactbtnFix'>
          <button onClick={()=>window.location.href='mailto:mr.oubaidayoussef@gmail.com'}><img src={Mail} alt="Contact Me" /></button>
        </div>
    </div>
  )
}

export default Socials