const google='images/technologies/google.svg';
const hacker='images/technologies/hackerrank.svg';
const dataSkills=[
    {
        title:"Frontend",
        names:["React","Redux","HTML","CSS","Sass","Javascript","Bootstrap","SEO"],
        images:["react.svg","redux.svg","html.svg","css3.svg","sass.svg","javascript.svg","bootstrap.svg","seo.svg"]
        ,certi:[
            ["React",hacker,"https://www.hackerrank.com/certificates/11d3e3041608"],
            ["Problem Solving (Basic)",hacker,"https://www.hackerrank.com/certificates/c0685f608056"],
            ["Problem Solving (Intermediate)",hacker,"https://www.hackerrank.com/certificates/28dc6269ea50"],
            ["Javascript (Basic)",hacker,"https://www.hackerrank.com/certificates/c91e2380a3f3"],
            ["Javascript (Intermediate)",hacker,"https://www.hackerrank.com/certificates/b1c376d64064"],
            ["Css",hacker,"https://www.hackerrank.com/certificates/2a1503aedaa0"]
        ]
    },
    {
        title:"Backend",
        names:["C#","Node.js"],
        images:["csharp.svg","node.svg"]
        ,certi:[
        ]
    },
    {
        title:"Database",
        names:["My Sql","Sql Server","Firebase"],
        images:["mysql.svg","sqlserver.svg","firebase.svg"]
        ,certi:[
        ["SQL(Basic)",hacker,"https://www.hackerrank.com/certificates/0967f1aa4c52"]
    ]
    },
    {
        title:"Design",
        names:["Figma","photoshop","illustrator","Canva"],
        images:["figma.svg","photoshop.svg","illustrator.svg","canva.svg"]
        ,certi:[]
    },
    {
        title:"Other",
        names:["VsCode","Google Analytics","Git","GitHub","bitbucket"],
        images:["Vscode.svg","google-analytics.svg","git.svg","github2.svg","bitbucket.svg"]
        ,certi:[["Digital Markiting ID: 8KS A99 K7L",google,"https://learndigital.withgoogle.com/digitalgarage/validate-certificate-code"]]
    }
]
export default dataSkills;